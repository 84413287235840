import store from '@/store';
import {
  VuexModule,
  Module,
  getModule,
  Action,
  Mutation,
} from 'vuex-module-decorators';
import predictionsApolloClient from '@/lib/appsync/workflow';
import setpointApolloClient from '@/lib/appsync/setpoint';
import defermentApolloClient from '@/lib/appsync/deferment';
import { getConfigEnv, jsonParse } from '@/utils/helpers';
import getOpsPagePredictionSummary from '@/graphql/predictions/queries/getOpsPagePredictionSummary.graphql';
import getSetpointCount from '@/graphql/predictions/queries/getSetpointCount.graphql';
import getDefermentAggLatest from '@/graphql/deferment/queries/getDefermentAggLatest.graphql';
import getDefermentAggLatestReport from '@/graphql/deferment/queries/getDefermentAggLatestReport.graphql';
import { DateTime } from 'luxon';

@Module({
  dynamic: true,
  namespaced: true,
  name: 'operationsModule',
  store,
})
class OperationsModule extends VuexModule {
  predictionTalliesDict = {};
  uplift = null
  optimized = null
  total = null
  defermentTotal = null

  topDefermentArray = []
  tasqsPerRoleArray = []
  tasqsPerRouteArray = []
  tasqsPerPredictionTypeArray = []
  totalTasqsListArray: any[] = []


  @Mutation
  resetTotalTasqsListArray(): void {
	  this.totalTasqsListArray = []
  }

  @Mutation
  resetTasqsPerPredictionTypeArray(): void {
	  this.tasqsPerPredictionTypeArray = []
  }

  @Mutation
  addTotalTasqsListItem(totalTasqsListDict): void {
	this.totalTasqsListArray.push(totalTasqsListDict)
  }

  @Mutation
  setDefermentTotal(defermentTotal): void {
    this.defermentTotal = defermentTotal
  }


  @Mutation
  setTasqsPerPredictionTypeArray(tasqsPerPredictionTypeArray): void {
    this.tasqsPerPredictionTypeArray = tasqsPerPredictionTypeArray
  }


  @Mutation
  setTasqsPerRouteArray(tasqsPerRouteArray): void {
    this.tasqsPerRouteArray = tasqsPerRouteArray
  }


  @Mutation
  setTasqsPerRoleArray(setTasqsPerRoleArray): void {
    this.tasqsPerRoleArray = setTasqsPerRoleArray
  }


  @Mutation
  setTopDefermentArray(topDefermentArray): void {
    this.topDefermentArray = topDefermentArray
  }


  @Mutation
  setPredictionTalliesDict(predictionTalliesDict): void {
    this.predictionTalliesDict = predictionTalliesDict
  }

  @Mutation
  setUplift(uplift): void {
    this.uplift = uplift
  }
  @Mutation
  setOptimized(optimized): void {
    this.optimized = optimized
  }
  @Mutation
  setTotal(total): void {
    this.total = total
  }



  @Action
  async getDefermentAggLatest({
	from_date,
	to_date,
  forReport = false,
  weekly = false,
  }): Promise<void> {
    let fromDate = from_date;
    if (forReport) {
      fromDate = weekly ? DateTime.local().minus({ days: 7 }).startOf('day').toFormat("y-MM-dd'T'HH:mm:ss")
        : DateTime.local().startOf('day').toFormat("y-MM-dd'T'HH:mm:ss");
    }

    const {
      data: {
        get_deferment_agg_latest: getDefermentAggLatestResult,
      },
    } = await defermentApolloClient.query({
      query: getDefermentAggLatest,
      variables: {
		  operator: getConfigEnv('OPERATOR_LOWERCASED'),
      from_date: fromDate,
      to_date: to_date || DateTime.local().toFormat("y-MM-dd'T'HH:mm:ss"),
      },
    });
	const parsedData = JSON.parse(getDefermentAggLatestResult.dataset);

	this.setDefermentTotal(getDefermentAggLatestResult.deferment_total)
	const data = parsedData.nodeid?.map((i, idx) => ({
		wellName: parsedData?.nodeid[idx],
		value: parseInt(parsedData?.Value[idx]),
		assignee: '',
		id: i,
		})).sort((a, b) => b.value - a.value);
		this.setTopDefermentArray(data)

  }





  @Action
  async getSetpointCount(): Promise<void> {
    const {
      data: {
        get_setpoint_count: getSetpointResults,
      },
    } = await setpointApolloClient.query({
      query: getSetpointCount,
      variables: {
          operator: getConfigEnv('OPERATOR_LOWERCASED')
      },
    });

	this.setUplift(JSON.parse(getSetpointResults.dataset).uplift)
	this.setOptimized(JSON.parse(getSetpointResults.dataset).optimized)
	this.setTotal(JSON.parse(getSetpointResults.dataset).total)

  }





  @Action
  async getPredictionTalliesOpt(): Promise<void> {
	this.resetTotalTasqsListArray()
	this.resetTasqsPerPredictionTypeArray()
    const {
      data: {
        get_ops_page_tallies: getOpsPagePredictionSummaryResults,
      },
    } = await predictionsApolloClient.query({
      query: getOpsPagePredictionSummary,
      variables: {
		Operator: getConfigEnv('OPERATOR_LOWERCASED'),
		Timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
      },
    });


	this.setTasqsPerRoleArray([
		{
			title: "Operators",
			value: 0 //getOpsPagePredictionSummaryResults.field_operator_unlabeled + getOpsPagePredictionSummaryResults.field_operator_completed
		},{
			title: "Workover",
			value: 0
		},{
			title: "Wireline",
			value: 0
		},{
			title: "Automation",
			value: 0 //getOpsPagePredictionSummaryResults.automation_unlabeled + getOpsPagePredictionSummaryResults.automation_completed
		},{
			title: "Engineering",
			value: 0 //getOpsPagePredictionSummaryResults.engineers_unlabeled + getOpsPagePredictionSummaryResults.engineers_completed
		},{
			title: "Optimizers",
			value: 0
		}
	])

	var routeDictParsed = JSON.parse(getOpsPagePredictionSummaryResults.RouteDict)
	var tasqsPerRouteArrayLocal: any[] = []
	for (var key in routeDictParsed){
		tasqsPerRouteArrayLocal.push({
			title: routeDictParsed[key].RouteName,
			value: routeDictParsed[key].JobCount
		})
	}
	this.setTasqsPerRouteArray(tasqsPerRouteArrayLocal)

	for (var h = 0; h < getOpsPagePredictionSummaryResults.TotalTasqList.length; h++) {
		this.addTotalTasqsListItem(JSON.parse(getOpsPagePredictionSummaryResults.TotalTasqList[h]))
	}

	var tasqsPerPredictionTypeArrayLocal: any[] = []
	var tasqsByPredictionTypeDict = {"Workflow": {title: "Workflow", value: 0}, "Predictions": {title: "Prediction", value: 0}, "Anomaly": {title: "Anomaly", value: 0}, "No Comms": {title: "No Comms", value: 0}, "Prolonged": {title: "Prolonged Anomaly", value: 0}, "Off-Target": {title: "Off-Target", value: 0}}
	for (var x = 0; x < this.totalTasqsListArray.length; x++) {
		var tasq = this.totalTasqsListArray[x]
		if (tasq.PredictionType == null || tasq.PredictionType == "Setpoint") {
			continue
		}
		var prediction_type = tasq.PredictionType
		if (prediction_type == "state change" || prediction_type == "Prolonged Anomaly") {
			prediction_type = "Anomaly"
		}
		if (!(prediction_type in tasqsByPredictionTypeDict)) {
			tasqsByPredictionTypeDict[prediction_type] = {
				name: prediction_type,
				value: 1
			}
		} else {
			tasqsByPredictionTypeDict[prediction_type].value += 1
		}
	}
	for (key in tasqsByPredictionTypeDict) {
		tasqsPerPredictionTypeArrayLocal.push(tasqsByPredictionTypeDict[key])
	}

	this.setTasqsPerPredictionTypeArray(tasqsPerPredictionTypeArrayLocal)


  }


}





export default getModule(OperationsModule);
